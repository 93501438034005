<template>
    <div class="builder-block" :class="spacerValue()">
        <div class="inner inner--small">
            <ol class="numberedList">
                <li v-for="(subField, subFieldKey) in block.fields['numbered-list__list_text']" :key="'sf-numberedlist-'+subFieldKey" :subFieldKey="subFieldKey" :subField="subField" >
                    <h3 :class="{'sand-text' :  block.fields['numbered-list__list_text_style'][subFieldKey].value == 'sandy' }">{{ block.fields['numbered-list__list_text'][subFieldKey].value }}</h3>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>

    export default {
        name: "NumberedList",
        props: {
            block: {
                type: Object,
                required: true
            }
        },
        methods: {
            spacerValue() {
                var spacer = this.block.fields['numbered-list__spacer'][0].value || '';
                if(spacer === 'xsmall') return 'mb-18'
                if(spacer === 'small') return 'mb-32'
                if(spacer === 'medium') return 'mb-54'
                if(spacer === 'large') return 'mb-80'
                if(spacer === 'xlarge') return 'mb-120'
            }
        }
    }
</script>
